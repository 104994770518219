// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    max-width: 90%;
    min-height: 90%;
    max-height: 90%;
    margin: 0 auto;
    background-color: #18191D;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px #C00086;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,4BAA4B;IAC5B,mBAAmB;AACvB","sourcesContent":[".app {\n    max-width: 90%;\n    min-height: 90%;\n    max-height: 90%;\n    margin: 0 auto;\n    background-color: #18191D;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px #C00086;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
