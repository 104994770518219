export const handTrapMap: string[] = [
    'Ash Blossom & Joyous Spring',
    'Ghost Belle & Haunted Mansion',
    'Ghost Ogre & Snow Rabbit',
    'Ghost Mourner & Moonlit Chill',
    'Ghost Reaper & Winter Cherries',
    'Ghost Sister & Spooky Dogwood',
    'Maxx "C"',    
    'Retaliating "C"',    
    'Contact "C"',    
    'Flying "C"',    
    'Dimension Shifter',    
    'Effect Veiler',    
    'Droll & Lock Bird',    
    'Nibiru, the Primal Being',    
    'Fantastical Dragon Phantazmay',    
    'Gnomaterial',    
    'Mulcharmy Fuwaross',    
    'Mulcharmy Purulia',    
    'D.D. Crow',    
    'Bystial Baldrake',    
    'Bystial Druiswurm',    
    'Bystial Magnamhut',    
    'Bystial Saronir',    
    'Skull Meister',
    'PSY-Framegear Alpha',    
    'PSY-Framegear Beta',    
    'PSY-Framegear Gamma',    
    'PSY-Framegear Delta',    
    'PSY-Framegear Epsilon',    
    'Infinite Impermanence',    
    'Typhoon',    
    'Red Reboot',    
    'Dominus Purge',    
    'Dominus Impulse',    
];